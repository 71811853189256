import { Autocomplete, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import ModelSelectionPopover from './ModelSelectionPopover';
import modelIcon from '../../../assets/icons/chat/model.svg';
import agentIcon from '../../../assets/icons/chat/agent.svg';
import modelDropdownIcon from '../../../assets/icons/chat/model-dropdown.svg';

export interface Option {
  id: number;
  providerName: string;
  displayName: string;
  description: string;
}

export interface AgentOption {
  id?: string;
  displayName: string;
}

interface Props {
  selectedItem?: string;
  isSecondary?: boolean;
}

const ModelTypeSelection: React.FC<Props> = ({ selectedItem, isSecondary }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);
  const [isDdlOpen, setIsDdlOpen] = useState<boolean>(false);
  const ddlRef = useRef();

  const handlePopoverOpen = () => {
    if (ddlRef?.current) {
      setPopoverAnchorEl(ddlRef?.current);
    }
  };

  const dropdownOptions = [
    { id: 1, providerName: 'Provider 1', displayName: 'Models', description: 'Chat with models' },
  ];

  return (
    <>
      <Autocomplete
        open={isDdlOpen}
        onOpen={() => {
          handlePopoverOpen();
        }}
        onClose={() => {
          setIsDdlOpen(false);
        }}
        defaultValue={dropdownOptions[0]}
        disableClearable={true} // This disables the clear button
        getOptionLabel={(option: Option) => `${option.displayName}`}
        sx={{ width: '22vw' }}
        ref={ddlRef}
        renderInput={params => {
          if (selectedItem) {
            params.inputProps.value = `${params.inputProps.value} - ${selectedItem}`;
            // params.inputProps.value = `${params.inputProps.value}`;
          }
          return (
            <AutoCompleteTextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disabled: true, // This disables the input
                style: {
                  cursor: 'none',
                  fontWeight: 'bold',
                }, // This hides the cursor
              }}
              placeholder={'Select'}
            />
          );
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={dropdownOptions}
      />
      <ModelSelectionPopover
        anchorEl={popoverAnchorEl}
        onClose={() => {
          setPopoverAnchorEl(null);
          setIsDdlOpen(false);
        }}
        setDropdownState={setIsDdlOpen}
        isSecondary={isSecondary}
      />
    </>
  );
};

export default observer(ModelTypeSelection);

const AutoCompleteTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: unset;
  }
  & .MuiInputLabel-root {
    top: 0;
  }
  & .MuiInputLabel-shrink {
    top: 0;
  }
`;
