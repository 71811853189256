import { Organization } from './Organization';

export interface ConfigurationField {
  type: string;
  value: any;
}

export interface ModelConfiguration {
  maxTokens?: ConfigurationField;
  maxTemperature?: ConfigurationField;
  maxRequestsPerHour?: ConfigurationField;
  supportsText?: ConfigurationField;
  supportsImage?: ConfigurationField;
  apiKey?: ConfigurationField;
  apiEndpoint?: ConfigurationField;
  [key: string]: ConfigurationField | undefined;
}

export interface OrganizationModel {
  id: string;
  organizationId: string;
  modelId: string;
  providerName: string;
  modelVersion: string;
  displayName: string;
  description: string;
  logoPicture?: string;
  type: string;
  config: ModelConfiguration;
  isActive: boolean;
  isDefault: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  organization?: Organization;
  isDefaultFileModel?: boolean;
}

export const EMPTY_ORGANIZATION_MODEL: OrganizationModel = {
  id: '',
  organizationId: '',
  modelId: '',
  providerName: '',
  modelVersion: '',
  displayName: '',
  description: '',
  logoPicture: '',
  type: '',
  config: {
    apiKey: { type: 'string', value: '' },
    apiEndpoint: { type: 'string', value: '' },
    maxTokens: { type: 'number', value: 2048 },
    maxTemperature: { type: 'number', value: 1 },
    maxRequestsPerHour: { type: 'number', value: 0 },
    supportsText: { type: 'boolean', value: false },
    supportsImage: { type: 'boolean', value: false },
  },
  isActive: false,
  isDefault: false,
  createdAt: undefined,
  updatedAt: undefined,
  organization: undefined,
  isDefaultFileModel: false,
};
